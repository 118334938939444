import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const Service = () => {
    const [service , setService] = useState({})
    const [lang  , setLang] = useState(localStorage.getItem('lang'))
    const params = useParams()

    useEffect(() => {
        const getLanguage = async () => {
            if(!localStorage.getItem('lang')){
            setLang(await localStorage.setItem('lang' , 'en'))
            }
        }

        const APICalls = async () => {
            const serviceResult = await fetch('https://algawhar.nativecourses.com/api/v1/services')
            const services = await serviceResult.json()
            const service = services.filter((value) => value.service_id == params.id)
            setService(service[0])
        }

        getLanguage()
        APICalls()
      } , [params.id])

    


  return (
    <main role="main" style={{ direction: lang === "ar" ? "rtl" : "ltr" }}>
        <header className="grid">
        	<div className="s-12">
            <h1 className="text-strong text-white text-center center text-size-60 text-uppercase margin-bottom-20">{lang === "en" ? service.service_name_en : service.service_name_ar}</h1>
            <img src={`https://dashboard.nativecourses.com/uploads/${service.image}`} style={{ width:'100%' , marginBottom: 15 }} />

          </div>
        </header>

        <section className="grid margin-bottom-20">
          <div className="m-12 l-12 center">
            <div className="s-12 padding-2x background-white text-center">                  
              <h2 className="text-size-40 margin-bottom-10 text-strong text-uppercase text-dark">{lang === "en" ? service.service_name_en : service.service_name_ar}</h2>                


              <div style={{ textAlign: lang === "en" ? "left" : "right" }} dangerouslySetInnerHTML={{ __html: lang === "en" ? (service.long_desc_en) : (service.long_desc_ar) }} />
              <h4 className=" margin-top-30 margin-bottom-10 text-uppercase text-dark">
                </h4>                
    <div className='grid' style={{ display: 'flex' , justifyContent:'center' , alignItems: 'center' }}>
    <a href="/" style={{ color: 'white' , backgroundColor: '#ff3333' , border: '2px solid white' , 
              padding: 10 , marginBottom: 15 , borderRadius: 30 , fontWeight: 'bold' , cursor: 'pointer' , fontSize: 17 }}>{lang === "en" ? 'Home Page' : 'الصفحة الرئيسية'}</a>
            </div>

            </div>
          </div>                  

        </section>
      </main>
  )
}

export default Service