import {useState , useEffect} from 'react';
import { Link } from 'react-router-dom';
export default function Header (){
    const [company , setCompany] = useState({})
    const [lang , setLang] = useState(localStorage.getItem('lang'))
    useEffect(() => {
        const getLanguage = async () => {
            if(!localStorage.getItem('lang')){
            setLang(await localStorage.setItem('lang' , 'en'))
            }
        }
        const getCompany = async () => {
            const res = await fetch('https://algawhar.nativecourses.com/api/v1/company')
            const data = await res.json()
            setCompany(data)
        }
        getLanguage()
        getCompany()
    } , [])
    return (
        <header className="grid">
      <nav className="s-12 grid background-none background-primary-hightlight" style={{ padding: 15 }}>
         <Link to="/" className="m-12 l-3 logo">
            <img src={`https://dashboard.nativecourses.com/uploads/${company.logo_header}`} style={{ width: 200 ,  marginTop: 0 }} alt="logo" />
         </Link>
         <div className="top-nav s-12 l-9"> 
            <ul className="top-ul right chevron">
              <li><Link to="/">{lang === "en" ? "Home" : "الرئيسية"}</Link></li>
              {/* <li><Link to="about-us.html">About Us</Link></li>
              <li><Link to="services.html">Services</Link></li>
              <li><Link to="gallery.html">Gallery</Link></li> */}
              <li><Link to="/contact">{lang === "en" ? "Contact" : "تواصل معنا"}</Link></li>
              <li><a href="#" onClick={async () => {
                  if(lang === 'en'){
                    await localStorage.setItem('lang','ar')
                    }
                    else{
                        await localStorage.setItem('lang','en')
                        }
                        window.location.reload()
              }}>{lang === "en" ? "عربي" : "English"}</a></li>
            </ul>
         </div>
      </nav>
    </header>
    )
}