import React, { useEffect, useState } from 'react'

const Contact = () => {
    const [company , setCompany] = useState({})
    const [lang , setLang] = useState(localStorage.getItem('lang'))
    function removeHTML(str){ 
      var tmp = document.createElement("DIV");
      tmp.innerHTML = str;
      return tmp.textContent || tmp.innerText || "";
  }
    useEffect(() => {
        const getLanguage = async () => {
            if(!localStorage.getItem('lang')){
            setLang(await localStorage.setItem('lang' , 'en'))
            }
        }
    const APICalls = async () => {
    const companyResult = await fetch('https://algawhar.nativecourses.com/api/v1/company')
    const company = await companyResult.json()
        setCompany(company)
        }
        getLanguage()
        APICalls()
    } , [])
  return (
    <main role="main" style={{ direction: lang === "ar" ? "rtl" : "ltr" }}>
        <header className="grid">
        	<div className="s-12 padding-2x">
            <h1 className="text-strong text-white text-center center text-size-60 text-uppercase margin-bottom-20">{lang === "en" ? "Contact Us" : "تواصل معنا"}</h1>
          </div>
        </header>
        
        <section className="grid margin-bottom-20">
          <div className="m-12 l-7 center">
            <div className="s-12 padding-2x background-white text-center">                  
              <h2 className="text-size-20 margin-bottom-10 text-strong text-uppercase text-dark">{lang === "en" ? "Company Address" : "عنوان الشركة"}</h2>                
              <p>{lang === "en" ? removeHTML(company.address_en) : removeHTML(company.address_ar)}</p>             

              <h2 className="text-size-20 margin-top-30 margin-bottom-10 text-strong text-uppercase text-dark">{lang === "en" ? "E-mail" : "البريد الإلكتروني"}</h2>                
              <p><a className="text-primary-hover" href={`mailto:${company.email}`}>{company.email}</a></p>

              <h2 className="text-size-20 margin-top-30 margin-bottom-10 text-strong text-uppercase text-dark">{lang === "en" ? "Phone Numbers" : "أرقام التليفونات"}</h2>                
              <a href={`tel:${company.phone}`}><p>{company.phone}</p></a>
              <a href={`tel:${company.phonecall}`}><p>{company.phonecall}</p></a>
            </div>

            <iframe className="s-12 center" 
            src={company.location} 
            title="map"
            height="450" frameBorder={0} style={{ border:0 }} allowFullScreen></iframe>  
          </div>                  

        </section>
      </main>
  )
}

export default Contact