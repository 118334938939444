import {useState , useEffect} from 'react';
export default function Footer (){
    const [company , setCompany] = useState({})
    const [lang , setLang] = useState(localStorage.getItem('lang'))
    function removeHTML(str){ 
        var tmp = document.createElement("DIV");
        tmp.innerHTML = str;
        return tmp.textContent || tmp.innerText || "";
    }
    useEffect(() => {
        const getLanguage = async () => {
            if(!localStorage.getItem('lang')){
            setLang(await localStorage.setItem('lang' , 'en'))
            }
        }
        const getCompany = async () => {
            const res = await fetch('https://algawhar.nativecourses.com/api/v1/company')
            const data = await res.json()
            setCompany(data)
        }
        getLanguage()
        getCompany()
    } , [])
    return (
        company &&
        <footer className="grid">
        <div className="s-12 l-3 m-row-3 margin-bottom background-image" style={{
            backgroundImage: `url(http://dashboard.nativecourses.com/uploads/${company.logo_header})`
            }}></div>
        
        <div className="s-12 m-9 l-3 padding-2x margin-bottom background-dark">
           <h2 className="text-strong text-uppercase">{lang === "en" ? "Who We Are?" : "من نحن؟"}</h2>
           <p>{lang === "en" ? removeHTML(company.long_desc_en) : removeHTML(company.long_desc_ar)}</p>
        </div>
     
     
        <div className="s-12 m-9 l-3 padding-2x margin-bottom background-dark">
           <h2 className="text-strong text-uppercase">{lang === "en" ? "Contact Us" : "تواصل معنا"}</h2>
           <p><b className="text-primary margin-right-10">P</b><a className="text-primary-hover" href={`tel:${company.phone}`}>{company.phone}</a></p>
           <p><b className="text-primary margin-right-10">M</b> <a className="text-primary-hover" href={`mailto:${company.email}`}>{company.email}</a></p>
        </div>
        
        <div className="s-12 text-center margin-bottom">
          <p className="text-size-12">{lang === "en" ? "Copyright" : "حقوق الملكية"} 2022, {lang === "en" ? company.company_name_en: company.company_name_ar}</p>
          <p><a className="text-size-12 text-primary-hover" rel="noreferrer" target={'_blank'} href="https://www.qodeex.com" title="Qodeex">{lang === "en" ? "Design and coding by Qodeex Team" : "صمم وطور بواسطة فريق كودكس"}</a></p>
        </div>
      </footer>
    )
}