import Footer from "./components/Footer";
import Header from "./components/Header";
import {BrowserRouter as Router , Routes , Route} from 'react-router-dom'
import {useEffect} from 'react'
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Service from "./components/Service";

function App() {
  useEffect(() => {
    const checkLang = async () => {
      await localStorage.getItem('lang')
      if(!localStorage.getItem('lang')){
        localStorage.setItem('lang','en')
      }
    }
    checkLang()
  } , [])
  return (
    <Router>
      <Header />

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/" exact={true} element={<Home />} />
          <Route path="/services/:id" element={<Service />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      
      <Footer />
    </Router>
  );
}

export default App;
